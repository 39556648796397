import { ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import React from 'react';

export const TextParser = ({ text, textGap = '8px', paragraphGap = '32px' }) => {
  return (
    <VStack spacing={paragraphGap} w="100%" align="start" fontSize="18px">
      {text?.map(({ text, list }, i) => {
        return (
          <VStack key={i} spacing={textGap} w="100%" align="start">
            {text?.map(stroke => {
              return (
                <Text key={stroke} textStyle="h1">
                  {stroke}
                </Text>
              );
            })}
            <VStack spacing="4px" w="100%" align="start">
              {list?.title && <Text textStyle="h1">{list?.title}</Text>}
              <UnorderedList ps="42px">
                {list?.items?.map(stroke => {
                  return (
                    <ListItem key={stroke} textStyle="h1">
                      {stroke}
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </VStack>
          </VStack>
        );
      })}
    </VStack>
  );
};
