import React, { memo } from 'react';
import { Text } from '@chakra-ui/react';
const BlockHeader = ({ children = null, ...props }) => {
  return (
    <Text as="h2" _after={{ content: `'.'`, color: 'bg.accent' }} {...props}>
      {children}
    </Text>
  );
};
export default memo(BlockHeader);
