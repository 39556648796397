import { Accordion, VStack } from '@chakra-ui/react';
import React from 'react';
import { data } from 'constants/cookies/contentOrder';
import BlockHeader from 'shared/BlockHeader';
import AccordeonItem from 'shared/AccordeonItem/AccordeonItem';

const CookiesPage = () => {
  return (
    <VStack align="start" spacing="120px" w="100%">
      <VStack spacing="48px" w="100%" align="left">
        <BlockHeader>Cookie Policy</BlockHeader>

        <Accordion allowMultiple w="100%" display="flex" flexDirection="column" gap="20px">
          {data?.map(({ title, content }, i) => (
            <AccordeonItem key={title} title={title} data={content} index={i} />
          ))}
        </Accordion>
      </VStack>
    </VStack>
  );
};

export default CookiesPage;
