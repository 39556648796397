export const whatIsCookies = [
  {
    text: [`ASTON groups cookies on our website as follows:`],
  },
  {
    text: [
      `ESSENTIAL COOKIES, INCLUDING PREFERENCE COOKIES`,
      `Essential cookies are necessary for ASTON’s sites to function properly and maintain security. They are also needed to provide services for website visitors, e.g. their privacy preferences and payments. You can only disable essential cookies via your browser settings. If you do this, it will affect your ability to use our services. These cookies are necessary for the website to function and cannot be switched off in our systems. They are also set in response to actions made by you and amount to a request for services, such as setting your privacy preferences, logging in, filling in forms and paying for services or training.`,
      `You can set your browser to block or alert you about these cookies, but some parts of the site will not work in this case. These cookies do not store any personally identifiable information.`,
      `A session cookie is set to remember your language preference when viewing the site.`,
    ],
  },
  {
    text: [
      `ANALYTICS COOKIES`,
      `These cookies provide information about how our sites are being used, so we can improve your experience. Cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us monitor which pages are the most and least popular and see how visitors navigate the site.`,
      `All the information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you visited our site, and will not be able to monitor its performance.`,
    ],
  },
  {
    text: [
      `MARKETING COOKIES`,
      `These cookies may be applied to our sites by third-party online service providers. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They collect information about your browsing habits on our and other websites, to make advertising relevant to you and your interests, to control how often those ads are shown to you, and to help measure the effectiveness of advertising campaigns. Data relating to your usage of our website and other websites may be analyzed to create profiles related to you and for you (i.e. ‘profiling’). This means that certain presumptions are made about what you may be interested in. That information is used to display advertising to you and other Internet users with similar browsing patterns. You have certain rights in relation to this type of data handling. If you object, you can manage and delete cookies.`,
      `These cookies do not store personal information directly, but are based on uniquely identifying your browser and Internet device. If you do not allow these cookies, you will still see adverts, but they will be less targeted.`,
    ],
  },
  {
    text: [
      `FURTHER DETAILS OF COOKIES ARE GIVEN BELOW`,
      `Please note that some third-party sites may place other cookies over which we have no control, and you may wish to adjust your browser settings accordingly`,
    ],
  },
];
