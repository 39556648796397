import { сontactDetailsCookies } from './ContentText/changeCookieUsage';
import { manageCookies } from './ContentText/manageCookies';
import { typesOfCookies } from './ContentText/typesOfCookies';
import { usingCookies } from './ContentText/usingCookies';
import { whatIsCookies } from './ContentText/whatIsCookies';

export const data = [
  { title: 'About our Cookie Policy', content: usingCookies },
  { title: `What categories of cookies do we use?`, content: whatIsCookies },
  {
    title: `How do I change my cookie settings?`,
    content: typesOfCookies,
  },
  { title: ` Third-party cookies`, content: manageCookies },
  { title: ` Contact details`, content: сontactDetailsCookies },
];
