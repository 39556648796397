import { AccordionButton, AccordionItem, AccordionPanel, Box, Image } from '@chakra-ui/react';
import React from 'react';
import Plus from 'assets/Icons/Plus.svg';
import Minus from 'assets/Icons/Minus.svg';
import { TextParser } from 'shared/TextParser';
import { Element } from 'react-scroll';
import { useCycle } from 'framer-motion';

const AccordeonItem = ({ title, data, index, ...props }) => {
  const [isExpanded, toggleIsExpanded] = useCycle(false, true);

  return (
    <Element name={`Item-${index}`}>
      <AccordionItem
        // p="32px 48px"
        w="100%"
        transition="background-color .2s, border .2s"
        bgColor={isExpanded ? 'transparent' : 'bg.grayLight'}
        border="1.6px solid"
        borderColor={isExpanded ? 'text.black' : 'transparent'}
        borderRadius="48px"
        textAlign="left"
        {...props}
      >
        <AccordionButton w="full" px="48px" py="32px" gap="15px" onClick={() => toggleIsExpanded()}>
          <Box textStyle="h1" flex="1" textAlign="left">
            {title}
          </Box>
          <Image src={isExpanded ? Minus : Plus} />
        </AccordionButton>
        <AccordionPanel pt="40px" px="32px" pb="48px">
          {<TextParser text={data} />}
        </AccordionPanel>
      </AccordionItem>
    </Element>
  );
};

export default AccordeonItem;
